.gradeMissions {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .left {
        display: flex;
        flex-direction: column;

        .selects {
            display: flex;
            gap: 3px;
        }
        
    }
}
// colors
$darkGreen: #5c8d89;
$green: #74b49b;
$lightGreen: #a7d7c5;
$veryLightGreen: #f4f9f4;
$darkGrey: #828282;
$white: #ffffff;
$lightGrey: #f2f2f2;

// font family
$fontFamily: "Andada Pro", serif;
$lightFont: 600;
$heavyFont: 800;

// fonts
$logoFont: calc(1.25 * 31.42px);

@import "./_variables2";

.TrySidebar2 {
    width: 100%;
    height: 100%;
    font-family: $fontFamily;
    background-color: $veryLightGreen;
    text-transform: capitalize;
    overflow: hidden;
    position: relative;
    
    h1 {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
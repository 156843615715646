.listsOfStudentGrades {
    height: 600px;
    padding: 20px;
    
    .title{
      width: 100%;
      font-size: 24px;
      color: gray;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
    }

    .showStudentGradesByExam {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;

        .title {
            width: 100%;
            font-size: 24px;
            color: gray;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        
        }

        .gradesOfSingleStudentExam {
            display: flex;
            justify-content: flex-start;
            gap: 1rem;
        
        }
    }
}
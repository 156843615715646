.TrySidebar {

    .nav {
        height: 60px;
        background-color: navy;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        color: white;

        .logo {
            display: flex;
            gap: 25px;
            align-items: center;

            h2 {
                cursor: pointer;
            }

            .icon {
                font-size: 23px;
                /* cursor: pointer; */
                
                @media screen and (min-width: 768px) {
                    
                    &.smallDeviceIcon {
                        display: none;
                    }
                    
                }
                
                @media screen and (max-width: 767px) {
                    
                    &.largeDeviceIcon {
                        display: none;
                    }

                }

            }
        }

        ul {
            list-style-type: none;
            display: flex;
            align-items: center;
            gap: 20px;

            li {
                cursor: pointer;
            }

        }

    }

    .SidebarContent {
        .SidebarContainer {
            background-color: green;
            color: white;
            width: 250px;
            height: calc(100vh - 60px);
            font-size: 20px;
            overflow-x: hidden;
            overflow-y: scroll;

            &::state(webkit-scrollbar) {
                display: none;
            }

            .nav-option {
                display: flex;
                align-items: center;
                padding: 0 0 0 30px;
                gap: 20px;
                width: 250px;

                &:hover {
                    background-color: white;
                    color: green;
                    cursor: pointer;
                }
            }

            @media screen and (min-width: 768px) {

                &.navCollapse {
                    width: 40px;
        
                    .nav-option {
                        padding: 10px;
        
                        h3 {
                            display: none;
                        }
        
                    }
                }
        
            }

            @media screen and (max-width: 767px) {

                &.smallNavCollapse {
                    display: none;
                }
            }

        }

    }

}
.lineAround {

    width: fit-content;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid gray;
    resize: both;
    overflow: auto;

    &.focus {
        border: 2px solid var(--basic-violet);
    }
}
@import "./_variables2";

.Sidebar2 {
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem 3rem;
    width: 15rem;
    height: 100%;
    transition: 0.5s all;

    .logoContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        h2.title {
            font-size: 31.42px;
            color: $green;
            padding: 0 0 0 1rem;
        }
    }

    .burgerContainer {
        content: "";
        background-color: $green;
        position: absolute;
        right: calc(-35px / 2);
        top: calc(9rem / 1.15);
        width: 35px;
        height: 35px;
        border-radius: 2rem;
        z-index: 1;

        .burgerTrigger {
            content: "";
            width: 35px;
            height: 35px;
            background-color: red;
            cursor: pointer;
            z-index: 6;
            opacity: 0;
            position: relative;
        }

        .burgerMenu {
            content: "";
            background-color: $green;
            position: relative;
            z-index: 1;
            width: 20px;
            height: 3px;
            border-radius: 2rem;
            bottom: calc(35px / 2);
            left: 0.45rem;
            transition: 0.5s all;
        }

        .burgerMenu::after {
            content: "";
            background-color: $white;
            position: absolute;
            width: 20px;
            height: 3px;
            border-radius: 2rem;
            top: 0.4rem;
            transform: translateY(-0.4rem) rotate(45deg);
            transition: 0.5s all;
        }

        .burgerMenu::before {
            content: "";
            background-color: $white;
            position: absolute;
            width: 20px;
            height: 3px;
            border-radius: 2rem;
            top: -0.4rem;
            transform: translateY(0.4rem) rotate(-45deg);
            transition: 0.5s all;
        }
    }

    .burgerContainer::after {
        content: "";
        position: absolute;
        right: 35px;
        top: (35px / 2);
        z-index: 0;
        width: calc(6rem + 15rem);
        background-color: $lightGrey;
        height: 2px;
        transition: 0.5s all;
    }

    .profileContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 3rem 0 0 0;

        img {
            width: auto;
            height: 3rem;
        }

        .profileContents {
            padding: 0 0 0 1rem;

            p.name {
                padding: 0;
                margin: 0;
                font-size: 16.7px;
                color: $darkGrey;
                text-transform: capitalize;
                font-weight: $heavyFont;
            }

            p {
                padding: 0;
                margin: 0;
                font-size: 13.9px;
                color: $darkGrey;
                text-transform: lowercase;
            }
        }
    }

    .contentsContainer {
        margin: 2rem 0 0 0;

        ul {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                margin: 0.75rem 0;
                padding: 0.75rem 0;
                width: 100%;
                border-radius: 1rem;
                cursor: pointer;

                img {
                    padding: 0 1rem 0 0.5rem;
                }

                a {
                    text-decoration: none;
                    color: $darkGrey;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 0 0 0.5rem;
                }
            }

            li.active {
                background-color: $veryLightGreen;

                a {
                    color: $green;
                }
            }

            li:hover {
                background-color: $lightGrey;
                transition: 0.25s;
            }
        }
    }
}

.Sidebar2.active {
    width: 3rem;
    transition: 0.5s all;

    .logoContainer.active {
        h2.title {
            opacity: 0;
        }
    }

    .burgerContainer.active {
        transition: 0.5s all;
        .burgerMenu {
            background-color: $white;
            transition: 0.5s all;
        }

        .burgerMenu::before {
            transform: translateY(0rem) rotate(0deg);
            transition: 0.5s all;
        }

        .burgerMenu::after {
            transform: translateY(0rem) rotate(0deg);
            transition: 0.5s all;
        }
    }

    .profileContainer {
        .profileContents {
            opacity: 0;
        }
    }

    .contentsContainer {
        a {
            opacity: 0;
        }
    }
}
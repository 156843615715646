.under-construction {
    text-align: center;
    font-size: 1em;
    padding: 2rem;
    
    .icon-container {
        padding: 2rem 0;
        display: flex;
        justify-content: center;
        gap: 2rem;
    }
    
    hr {
        margin: 0;
        height: 0;
        border: 2px solid var(--basic-green);
    }
    
    .icon {
        color: var(--basic-violet);
        font-size: 4rem;
    }
    
    h1 {
        color: var(--basic-violet);
    }
}
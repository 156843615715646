:root {
    --basic-violet: #6439ff;
}

.app {
    height: 100vh;
    
    .page-container {
        
        display: flex;
        height: 100%;
        
        .nav-content-container {
            
            height: 100%;

            &.narrow {
                flex: 12;
            }

            &.wide {
                flex: 20 1 95%;
            }
            
        }
    }
}
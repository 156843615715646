.pythonPractice {

    .title {

        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .selectPracticeAndExercise {
            display: flex;
            justify-content: space-between;
        }
    }


}
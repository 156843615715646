.sidebarParent {
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 130px;
    width: 130px;
    position: relative;
    transition: flex 0.3s ease;
    transition: width 0.3s ease;

    &.east {
        /* flex: 1 1 5%; */
        flex-grow: 1;
        flex-shrink: 2;
        flex-basis: 5%;
        width: 90px;
    }

.sidebar {
    border-right: 0.5px solid rgb(230,227,227);
    min-height: 100vh;
    background-color:white;
    position: fixed;
    width: inherit;
    max-width: inherit;

    .top {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            font-size: 20px;
            font-weight: bold;
            color: #6439ff;
            &.narrow {
                position: absolute;
                left: 1px;
                top: 10px;
            }
        }

        button {
            // width: 150px;
            // padding: 10px;
            // border: none;
            background-color: transparent;
            color: #6439ff;
            font-weight: bold;
            cursor: pointer;
            position: absolute;
            border: 1px solid #6439ff;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 1px;

            &.east {
                /* left: -26px; */
                right: 1px;
            }
          }
    }

    hr {
        height: 0;
        border: 0.5px solid rgb(230,227,227);
    }

    .center {
        padding-left: 10px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
 
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1) {
                background-color: whitesmoke;
            }
            &:nth-child(2) {
                background-color: #333;
            }
            &:nth-child(3) {
                background-color: darkblue;
            }
        }

    }
}
/* .i-am-a-skeleton {
    flex: 1;
} */
}
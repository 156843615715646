.TagButton {
    padding: 4px 5px;
    border-radius: 5px;
    opacity: 0.5;
    cursor: pointer;

    &.green {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.golden {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.red {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
    &.pink {
      background-color: rgba(255, 0, 0, 0.05);
      color: rgb(219, 118, 138);
    }
    &.blue {
      background-color: rgba(0, 0, 255, 0.05);
      color: rgb(109, 103, 224);
    }

    &.chosen {
        opacity: 1;
    }
  }
.mainForm {
  
  & > .errMsg {
    color: red;
    font-size: 0.5rem;
    font-style: italic;
    opacity: 0;
  }

  & > .errMsg.visible {
    opacity: 1;
  }

  .formContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;
    align-items: flex-end;
    
    button {
      width: 150px;
      padding: 10px;
      border: none;
      background-color: teal;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin-top: 10px;
    }
  }
}

.formInput {
    width: 40%;

    label {

      text-transform: capitalize;
      position: relative;

      .required {
        vertical-align: super;
        position: relative;
        top: 0.2em;
      }
    }

    input {
      width: 100%;
      padding: 5px;
      border: none;
      border-bottom: 1px solid gray;
    }

    .errMsg {
      color: red;
      font-size: 0.5rem;
      font-style: italic;
      opacity: 0;
    }

    .errMsg.visible {
      opacity: 1;
    }
}


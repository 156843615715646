.formInput {
    width: 40%;

    .digitInputs {

      display: flex;
      width: 100%;
      padding: 5px;
      border: none;
      border-bottom: 1px solid gray;
      
      input {
        font-size: 2rem;
        width: 3ch;
        text-align: center;
        padding: 0.5rem 0;
        box-shadow: inset 2px 2px 2px gray;
        border-radius: 3px;
        margin-right: 1px;
      }

    }

    .errMsg {
      color: red;
      font-size: 0.8rem;
      font-style: italic;
      opacity: 0;
    }

    .errMsg.visible {
      opacity: 1;
    }
}


.formSelect {

    label {
      display: flex;
      align-items: center;
      gap: 10px;
      text-transform: capitalize;

      .icon {
        cursor: pointer;
      }
    }

    select {
      width: 100%;
      padding: 5px;
      border: none;
      border-bottom: 1px solid gray;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .errMsg {
      color: red;
      font-size: 0.5rem;
      font-style: italic;
      opacity: 0;
    }

    .errMsg.visible {
      opacity: 1;
    }
}
.sidebarItem {

    transition: all 0.25s ease-in-out;

    &:hover {
        background-color: #ece8ff;
    }

    &.active {
        background-color: #e0dafa;
    }

    .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;;
    }

    span.narrow {
        display: none;
    }

    li {

        padding: 5px;
        cursor: pointer;
        
        .link {

            display: flex;
            align-items: center;

            
            .icon {
                font-size: 18px;
                color: #7451f8;
            }
            
            span {
                font-weight: 600;
                color: #888;
                margin-left: 10px;
            }

            span.navCollapse {
                display: none;
            }

        }

    }
 
}
.ediTable {
  padding: 20px;
  overflow: scroll;

  .cellAction {
    padding-left: 10px;
  }

  .btn {
    width: 150px;
    padding: 10px;
    border: none;
    background-color: teal;
    
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;

    border-radius: 4px;
    line-height: 1.2rem;
    font-size: 0.875rem;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
    &:hover {
      background-color: rgba(25,118,210,0.04);
    }
  }

  

  .datagrid {
    min-height: 1000px!important;
    resize: vertical;
  }
  
  .MuiDataGrid-row {
    max-height: 90px!important;

  }

  .ediTableTitle{
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active, &.unknown, &.teacher {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending, &.course_viewer {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive, &.school_admin {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
    &.female, &.admin {
      background-color: rgba(255, 0, 0, 0.05);
      color: rgb(219, 118, 138);
    }
    &.male, &.student {
      background-color: rgba(0, 0, 255, 0.05);
      color: rgb(109, 103, 224);
    }
  }

  .css-14mxsc7-MuiDataGrid-root  .MuiDataGrid-actionsCell {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0 5px 0 20px ;
    margin: 0 10px 0 10px;
    width: fit-content;

    .actionBtn {
      display: flex;
      align-items: center;

      &:hover {
        box-shadow: 2px 2px 2px darkblue;
      }

      &:active {
        box-shadow: inset 2px 2px 2px darkblue;
      }

      svg {
        filter: drop-shadow(0 0 20px #1b5e20);
        filter: drop-shadow(4px 4px 1px #b71c1c);
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      }
    }

    .saveButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: #07bc0c;
      border: 1px dotted rgba(7, 188, 12, 0.596);
      border: 1px dotted rgba(7, 188, 12, 1);
      cursor: pointer;
    }
    .editButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: rgb(52, 152, 219);
      border: 1px dotted rgba(52, 152, 219, 1);
      cursor: pointer;
    }
    .cancelButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: rgb(241, 143, 15);
      border: 1px dotted rgba(241, 143, 15, 1);
      cursor: pointer;
    }
    .copyButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: rgb(6, 153, 114);
      border: 1px dotted rgba(6, 153, 114, 0.596);
      border: 1px dotted rgba(6, 153, 114, 1);
      cursor: pointer;
    }
    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }
    
    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }

  .css-wop1k0-MuiDataGrid-footerContainer {
    justify-content: flex-start; // moves pagination controls
  }

  .css-1b9e9gy {
    left: 0; // moves table vertical scrollbar
  }
}
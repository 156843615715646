.showGradesByExam {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;

    .title {
        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
    }
}
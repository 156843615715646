.listsOfGrades {
    height: 600px;
    padding: 20px;
    
    .title{
      width: 100%;
      font-size: 24px;
      color: gray;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
    }
  
    button.tab.Mui-selected {
      color: var(--basic-violet);
    }
  
    .css-1aquho2-MuiTabs-indicator {
        background-color: var(--basic-violet);
    }
 
  } 
.specificGames {

    background: linear-gradient(to top right, 
               transparent,lightseagreen);

    .title {
        font-size: 230%;
        font-family: fantasy;
        color: white;
        text-shadow: 5px 5px 5px grey;
    }           

}
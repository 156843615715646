.gameGallery {

    background: linear-gradient(to top right, 
               transparent,lightseagreen);
    height: 100%;

    .title {
        font-size: 230%;
        color: white;
        text-shadow: 5px 5px 5px grey;
    }           

}